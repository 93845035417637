module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Erik André Jakobsen","short_name":"eaj.no","start_url":"/","background_color":"#f0f0f0","theme_color":"#bb002d","display":"standalone","icon":"src/images/icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"b32fff296bc9404e9c9624dbad232382"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":["gatsby-plugin-sharp","gatsby-remark-katex",{"resolve":"gatsby-remark-images","options":{"maxWidth":850,"backgroundColor":"transparent","wrapperStyle":"\n                margin: 2rem auto;\n                box-shadow: 0 0.5rem 2rem rgba(0, 0, 0, 0.1);\n              "}},"gatsby-remark-code-titles",{"resolve":"gatsby-remark-prismjs","options":{"inlineCodeMarker":"›"}}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    }]
